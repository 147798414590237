import * as React from 'react';

export type APIAccessContextState = {
  token: string;
  apiUrl: string;
  setToken: (token: string) => void;
};

export const APIAccessContext = React.createContext<APIAccessContextState | undefined>(undefined);

export const APIAccessProvider: React.FC<React.PropsWithChildren<
  Omit<APIAccessContextState, 'setToken'>
>> = ({ token: initialToken, apiUrl, children }) => {
  const [token, setToken] = React.useState<string>(initialToken);

  React.useEffect(() => {
    setToken(initialToken);
  }, [initialToken]);

  return (
    <APIAccessContext.Provider value={{ token, apiUrl, setToken }}>
      {children}
    </APIAccessContext.Provider>
  );
};

export const useAPIAccess = () => {
  const context = React.useContext(APIAccessContext);
  if (!context) {
    throw new Error('Usage of "useAPIAccess" should be within the APIAccessProvider component');
  }
  return context;
};
