import * as React from 'react';
import { useTheme } from 'styled-components';
import type { ThemeBreakpoints } from '@edapp/themes';

/**
 *
 * @param query Media query to be used. Consider if `useBreakpointMatchMedia`
 * fits your needs better, as it uses standard breakpoints for media queries
 *
 * @returns whether or not the current viewport dimensions match your query
 */
function useMatchMedia(query: string) {
  const matchListRef = React.useRef<MediaQueryList | null>(null);
  const [isMatch, setIsMatch] = React.useState<boolean | undefined>(
    typeof window !== 'undefined' ? window.matchMedia(query).matches : undefined
  );

  const handleMediaQueryListEvent = React.useCallback((e: MediaQueryListEvent) => {
    setIsMatch(e.matches);
  }, []);

  React.useEffect(() => {
    matchListRef.current = window.matchMedia(query);
    matchListRef.current.addListener(handleMediaQueryListEvent);
    setIsMatch(matchListRef.current.matches);

    return () => {
      if (matchListRef.current) {
        matchListRef.current.removeListener(handleMediaQueryListEvent);
      }
    };
  }, [query]);

  return isMatch;
}

/**
 *
 * @param breakpoint Which breakpoint should the media query use
 * @param direction Which direction the media query operates on,
 * either looking at min or max width
 *
 * @returns whether or not the current viewport dimensions match your query
 */
const useBreakpointMatchMedia = (
  breakpoint: ThemeBreakpoints,
  direction: 'min-width' | 'max-width'
) => {
  const { breakpoints } = useTheme();
  const isMatch = useMatchMedia(`only screen and (${direction}: ${breakpoints[breakpoint]}rem)`);

  return isMatch;
};

export { useMatchMedia, useBreakpointMatchMedia };
