import * as React from 'react';

import type { ErrorResponseType, ValidationErrorResponseType } from './errors';
import { ErrorCode } from './errors';
import type {
  EdExceptionType,
  FetchMethodType,
  HippoError,
  PaginatedResponse,
  TokenParameter,
  UseErrorExtractionResult
} from './types';
import {
  DEFAULT_ERROR_MESSAGE,
  EdException,
  extractError,
  getEdErrorResponse,
  httpFetch
} from './utils';

type RequestProps<TVariables> = {
  method: FetchMethodType;
  userToken?: TokenParameter;
  hippoUrl?: string;
  uri: string;
  variables?: TVariables;
  skip?: boolean; // does not trigger refetch, unless calling refetch manually
  onSuccess?: () => void;
};

type PaginatedProps<TData> = {
  initialFetch: boolean;
  pageSize: number;
  identityFn?: (items: TData[], newItems: TData[]) => TData[];
};

type FetchProps<TVariables> = {
  method: FetchMethodType;
  url: string;
  variables?: TVariables;
  includeCredentials?: boolean;
  headers?: {};
  skip?: boolean; // does not trigger refetch, unless calling refetch manually
  isFormData?: boolean; // form have a different content-type
};

const getEndpointUrl = (dirtyUri: string, hippoUrl?: string): string => {
  const cleanUri = dirtyUri.indexOf('/') === 0 ? dirtyUri.slice(1) : dirtyUri;
  const cleanUrl = (urlToClean: string) =>
    urlToClean.charAt(urlToClean.length - 1) === '/'
      ? urlToClean.substring(0, urlToClean.length - 1)
      : urlToClean;

  return !!hippoUrl ? `${cleanUrl(hippoUrl)}/${cleanUri}` : cleanUri;
};

const useHippoRequest = <TData, TVariables = {}>({
  uri,
  method,
  userToken,
  variables,
  hippoUrl,
  skip,
  onSuccess
}: RequestProps<TVariables>) => {
  const [state, setState] = React.useState<{
    loading: boolean;
    error: string | null;
    errorObject?: EdExceptionType;
    data?: TData;
  }>({
    loading: false,
    error: null,
    data: undefined
  });

  const url = getEndpointUrl(uri, hippoUrl);

  const fetchData = async (params?: TVariables) => {
    setState(state => ({ ...state, loading: true }));

    try {
      const result = await httpFetch<TData, TVariables>(method, url, userToken, params!);
      setState(state => ({ ...state, data: result, loading: false, error: null }));
      onSuccess?.();
    } catch (error) {
      setState(state => ({
        ...state,
        error: getEdErrorResponse(error),
        errorObject: error as EdExceptionType,
        loading: false
      }));
    }
  };

  React.useEffect(() => {
    if (!!skip || (!!hippoUrl && !userToken)) {
      return;
    }
    fetchData(variables);
  }, []);

  const reset = () => {
    setState({
      loading: false,
      error: null,
      data: undefined
    });
  };

  return { ...state, fetch: fetchData, reset };
};

const usePaginatedRequest = <TData, TVariables extends {}>({
  uri,
  method,
  hippoUrl,
  userToken,
  initialFetch,
  pageSize,
  variables,
  identityFn
}: RequestProps<TVariables> & PaginatedProps<TData>) => {
  const [currentPage, setCurrentPage] = React.useState<number>(0);
  const [itemList, setItemList] = React.useState<TData[]>([]);
  const [pageLoading, setPageLoading] = React.useState<boolean>(initialFetch);

  const fetchPaginatedData = async (page: number, params?: TVariables) => {
    setPageLoading(true);
    fetch({
      pageSize,
      page,
      ...params
    });
    setCurrentPage(page);
  };

  const { data, error, loading: fetching, fetch } = useHippoRequest<PaginatedResponse<TData>>({
    uri,
    method,
    hippoUrl,
    userToken,
    skip: true, // Skip hippo fetch and use the useEffect 'fetchPaginatedData'
    variables
  });

  React.useEffect(() => {
    if (!!initialFetch && !data) {
      fetchPaginatedData(currentPage + 1, variables);
    }
  }, [initialFetch]);

  React.useEffect(() => {
    if (!fetching && !error && data?.items) {
      setPageLoading(false);

      if (!!identityFn) {
        return setItemList([...identityFn(itemList, data?.items || [])]);
      }

      if (currentPage === 1) {
        return setItemList([...data.items]);
      }

      if (pageSize * currentPage > itemList.length) {
        return setItemList([...itemList, ...data.items]);
      }
    }
  }, [data, fetching]);

  const totalCount = data?.totalCount;
  const hasMore =
    !data ||
    (!!totalCount && totalCount > itemList.length && pageSize * currentPage === itemList.length);

  const paginated = {
    onLoadMore: () => {
      if (!hasMore || pageLoading) {
        return;
      }
      fetchPaginatedData(currentPage + 1, variables);
    },
    hasMore
  };

  const resetItems = () => {
    setItemList([]);
  };

  return {
    paginated,
    itemList,
    error,
    loading: pageLoading,
    fetch: fetchPaginatedData,
    resetItems,
    totalCount
  };
};

const useFetch = <TData, TVariables = {}>({
  url,
  method,
  variables,
  includeCredentials = false,
  headers,
  skip,
  isFormData
}: FetchProps<TVariables>) => {
  const [data, setData] = React.useState<TData | undefined>(undefined);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<EdExceptionType | null>(null);
  const [success, setSuccess] = React.useState<boolean>(false);
  const abortControllerRef = React.useRef<AbortController | null>(null);

  const fetchData = async (params?: TVariables) => {
    if (window.Request.prototype.hasOwnProperty('signal') && 'AbortController' in window) {
      abortControllerRef.current = new AbortController();
    }

    setLoading(true);
    setSuccess(false);
    try {
      const contentType = {
        'content-type': 'application/json; charset=utf-8'
      };
      const response = await fetch(url, {
        method,
        credentials: includeCredentials ? 'include' : 'same-origin',
        headers: {
          Accept: 'application/json',
          ...(!isFormData && contentType),
          ...headers
        },
        signal: abortControllerRef.current?.signal,
        body: !!isFormData && params ? (params as any) : JSON.stringify(params) // body data type must match "Content-Type" header
      }).then(async (response: Response) => {
        if (response.status !== 200 && response.status !== 204) {
          // this is the default error
          let errorResponse: ErrorResponseType | ValidationErrorResponseType = {
            type: 'Error',
            message: DEFAULT_ERROR_MESSAGE,
            code: ErrorCode.NotSpecified,
            statusCode: response.status
          };

          try {
            errorResponse = await response.json();
            // this error logic is coming from back-end
            // Hippo.Api -> ErrorHandlerMiddleware.cs
            errorResponse.type = 'validationErrors' in errorResponse ? 'ValidationError' : 'Error';
          } catch (err) {
            // ignore
          }

          throw EdException(
            `${method} request to ${url} resulted in a ${response.status} HTTP status code.`,
            response,
            response.status,
            url,
            params,
            errorResponse
          );
        }

        return response;
      });

      const result = await response.text();
      let parsedResult;
      try {
        parsedResult = JSON.parse(result);
      } catch (error) {
        parsedResult = {
          data: result
        };
      }
      setData(parsedResult);
      setSuccess(true);
      setLoading(false);
      return parsedResult;
    } catch (error) {
      setError(error as EdExceptionType);
      setLoading(false);
      return error;
    }
  };
  React.useEffect(() => {
    if (!!skip) {
      return;
    }
    fetchData(variables);
  }, []);

  const reset = () => {
    setLoading(false);
    setError(null);
    setData(undefined);
    setSuccess(false);
    abortControllerRef.current = null;
  };

  const cancel = () => {
    abortControllerRef.current?.abort();
  };

  return { data, loading, error, fetch: fetchData, reset, cancel, success };
};

function useErrorExtraction(error: HippoError | null): UseErrorExtractionResult {
  return React.useMemo(() => extractError(error), [error]);
}

export { useHippoRequest, useFetch, usePaginatedRequest, useErrorExtraction };
