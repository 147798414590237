/**
 * UserPermission enum are values/constants defined by the FE.
 * Describing what things the user can or cannot do
 */
export enum UserPermission {
  ACCESS_LMS = 'access-lms',
  ACCESS_APP = 'access-app',
  TRACK_ANALYTICS = 'track-analytics',
  TRACK_PROGRESS = 'track-progress',
  ACCESS_PRIZING = 'access-prizing',
  HIDE_USER = 'hide-user',

  VIEW_COURSEWARE = 'view-courseware',
  EDIT_CONTENT = 'edit-content',
  /** Deprecated, was associated with the old Restricted Author role which has been removed */
  EDIT_COURSEWARE_RESTRICTED = 'edit-courseware-restricted',

  // Courseware permissions that are more granular than VIEW_COURSEWARE and EDIT_CONTENT
  FILTER_COURSES_BY_USER_GROUP = 'filter-courses-by-user-group',
  FILTER_COURSES_BY_ACCESS = 'filter-courses-by-access',
  EDIT_BRAIN_BOOST = 'edit-brain-boost',
  VIEW_RAPID_REFRESH = 'view-rapid-refresh',
  EDIT_RAPID_REFRESH = 'edit-rapid-refresh',
  EDIT_PATHS = 'edit-paths',
  CREATE_COLLECTIONS = 'create-collections',
  EDIT_COLLECTIONS = 'edit-collections',
  VIEW_COURSE_LIBRARY = 'view-course-library',

  EDIT_USERS = 'edit-users',
  EDIT_SETTINGS = 'edit-settings',
  ACCESS_AUTHORING = 'access-authoring',
  POST_REPLY = 'post-reply',
  EDIT_ANY_COMMENT = 'edit-any-comment',

  ACCESS_SEO_CONTENT = 'access-seo-content',
  VIEW_USER_ANALYTICS = 'view-user-analytics',
  VIEW_CONTENT_SOCIAL = 'view-content-social',
  VIEW_SOCIAL_ANALYTICS = 'view-social-analytics',
  MANAGER_ANALYTICS = 'manager-analytics',
  VIEW_CONTENT_ANALYTICS = 'view-content-analytics',
  VIEW_COURSE_SUMMARY_ANALYTICS = 'view-course-summary-analytics',
  VIEW_BILLING = 'view-billing',
  EDIT_INTEGRATIONS = 'edit-integrations',
  ACCESS_AUDIT_TRAIL = 'access-audit-trail',
  FACILITATE_PRACTICAL_ASSESSMENTS = 'facilitate-practical-assessments',
  FACILITATE_GROUP_TRAINING = 'facilitate-group-training',
  FACILITATE_DISCUSSIONS = 'facilitate-discussions',
  FACILITATE_ASSIGNMENTS = 'facilitate-assignments',

  // This permission is associated with the SafetyCulture Training Manager role
  SC_MANAGE_TEAM = 'sc-manage-team'
}

export enum CourseUserPermissions {
  VIEW = 'View',
  EDIT = 'Edit',
  EDIT_SETTINGS = 'EditSettings',
  DELETE = 'Delete',
  PUBLISH = 'Publish',
  SHARE = 'Share',
  ASSIGN = 'Assign'
}

export enum PermissionScopes {
  VIEW = 'VIEW',
  CREATE = 'CREATE',
  PUBLISH = 'PUBLISH',
  DELETE = 'DELETE',
  EDIT = 'EDIT',
  REORDER = 'REORDER',
  POST_REPLY = 'POST_REPLY',
  ADVANCED_MENU = 'ADVANCED_MENU',
  CHANGE_LOG = 'CHANGE_LOG',
  BRANDING = 'BRANDING',
  DISPLAY = 'DISPLAY',
  ACCESS_RULES = 'ACCESS_RULES',
  BRAIN_BOOST = 'BRAIN_BOOST',
  BRIEFCASE = 'BRIEFCASE',
  COMPLETION = 'COMPLETION',
  MORE = 'MORE',
  CALENDAR = 'CALENDAR',
  UTILITIES = 'UTILITIES',
  REVIEW_LESSON_TEXT = 'REVIEW_LESSON_TEXT',
  PUSH_NOTIFICATIONS = 'PUSH_NOTIFICATIONS',
  DYNAMIC_USER_GROUPS = 'DYNAMIC_USER_GROUPS',
  PRIZING = 'PRIZING',
  EDIT_ANY_COMMENT = 'EDIT_ANY_COMMENT',
  ACCESS_ANALYTICS = 'ACCESS_ANALYTICS',
  FILTER_BY_USER_GROUP = 'FILTER_BY_USER_GROUP',
  FILTER_BY_ACCESS = 'FILTER_BY_ACCESS',
  FACILITATE = 'FACILITATE'
}

/**
 * PermissionResources are the entities.
 *
 * @example
 * You can CREATE (scope) a COURSE (resource), but you can't EDIT (scope) a COURSE (resource).
 *
 */
export enum PermissionResources {
  COURSE_COLLECTION = 'COURSE_COLLECTION',
  COURSE = 'COURSE',
  COURSE_LIBRARY = 'COURSE_LIBRARY',
  LESSON = 'LESSON',
  DISCUSSION = 'DISCUSSION',
  ASSESSMENT = 'ASSESSMENT',
  OBSERVATION = 'OBSERVATION',
  USER = 'USER',
  APPLICATION_SETTINGS = 'APPLICATION_SETTINGS',
  PLAYLIST = 'PLAYLIST',
  BANNER = 'BANNER',
  VIRTUAL_CLASSROOM = 'VIRTUAL_CLASSROOM',
  RAPID_REFRESH = 'RAPID_REFRESH',
  BILLING = 'BILLING'
}

/**
 * BillingPermission enum are values/constants that come from the backend.
 * describing what the billing plan enables for the user
 *
 * @example
 * You can ACCESS_SPACED_REPETITION only if you billing plan allows
 */
export enum BillingPermission {
  ACCESS_SPACED_REPETITION = 'access-spaced-repetition',
  ACCESS_USER_GROUPS = 'access-user-groups',
  ACCESS_DYNAMIC_USER_GROUPS = 'access-dynamic-user-groups',
  ACCESS_PUSH_NOTIFICATIONS = 'access-push-notifications',
  ACCESS_LEADERBOARDS = 'access-leaderboards',
  ACCESS_PRIZING = 'access-prizing',
  ACCESS_XAPI = 'access-xapi',
  ACCESS_API = 'access-api',
  ACCESS_SSO = 'access-sso',
  ACCESS_SCORM = 'access-scorm',
  ACCESS_AI_TRANSLATION = 'access-ai-translation',
  ACCESS_DISCUSSION_AND_ASSIGNMENT = 'access-discussion-and-assignment',
  ACCESS_PLAYLIST = 'access-playlist',
  ACCESS_VIRTUAL_CLASSROOM = 'access-virtual-classroom',
  ACCESS_ACCOUNT_DETAILS = 'access-account-details',
  ACCESS_BILLING_DETAILS = 'access-billing-details',
  ACCESS_INTEGRATIONS = 'access-integrations',
  ACCESS_CUSTOM_CERTIFICATES = 'access-custom-certificates',
  ACCESS_DOWNLOAD_CERTIFICATES = 'access-download-certificates',
  ACCESS_MULTIPLE_CERTIFICATE_TEMPLATES = 'access-multi-certificate-templates',
  ACCESS_ADVANCED_TEMPLATE_LIBRARY = 'access-advanced-template-library',
  CAN_SHOW_UPGRADE_ACTIONS = 'can-show-upgrade-actions',
  ACCESS_MANUAL_TRANSLATIONS = 'access-manual-translations',
  ACCESS_FEEDBACK = 'access-feedback',
  ACCESS_COURSE_COLLECTION = 'access-course-collections',
  ACCESS_COURSEWARE_REVIEW_TOOL = 'access-courseware-review-tool',
  ACCESS_GET_STARTED_CHECKLIST = 'access-get-started-checklist',
  ACCESS_CUSTOM_SPLASH_SCREEN = 'access-custom-splash-screen',
  ACCESS_FOCUS = 'access-focus',
  ACCESS_DEEPLINKS = 'access-deeplinks',
  ACCESS_OFFLINE_MODE = 'access-offline-mode',
  ACCESS_STARS = 'access-stars',

  // Billing access permissions for analytics
  ACCESS_ANALYTICS_RAPID_REFRESH = 'access-analytics-rapid-refresh',
  ACCESS_ANALYTICS_ACTIVITY_FEED = 'access-analytics-activity-feed',
  ACCESS_ANALYTICS_APP_USAGE = 'access-analytics-app-usage',
  ACCESS_ANALYTICS_LOGIN_STATS = 'access-analytics-login-stats',
  ACCESS_ANALYTICS_ACTIONABLE_REPORTS = 'access-analytics-actionable-reports',
  ACCESS_ANALYTICS_USERS = 'access-analytics-users',
  ACCESS_ANALYTICS_USERS_CONTENT_DRILLDOWN = 'access-analytics-users-content-drilldown',
  ACCESS_ANALYTICS_STARS = 'access-analytics-stars',
  ACCESS_ANALYTICS_SOCIAL = 'access-analytics-social',
  ACCESS_ANALYTICS_MANAGER = 'access-analytics-manager',
  ACCESS_ANALYTICS_COURSE_PROGRESS = 'access-analytics-course-progress',
  ACCESS_ANALYTICS_SURVEYS = 'access-analytics-surveys',
  ACCESS_ANALYTICS_COURSE_COMPLETION_BY_USER = 'access-analytics-course-completion-by-user',
  ACCESS_ANALYTICS_COURSE_COMPLETION_BY_USER_GROUP = 'access-analytics-course-completion-by-group',
  ACCESS_PERFORMANCE_DASHBOARD = 'access-performance-dashboard',

  ACCESS_SUPPORT_CENTRE = 'access-support-centre',
  ACCESS_NOTIFICATION_CENTRE = 'access-notification-centre',
  ACCESS_LINK_ACCOUNTS = 'access-link-accounts',
  ACCESS_SWITCH_ACCOUNTS = 'access-switch-accounts',
  ACCESS_COURSE_REVIEW = 'access-course-review',
  ACCESS_RECURRING_CERTIFICATION = 'access-recurring-certification',
  ACCESS_LEARNER_NOTIFICATIONS = 'access-learner-notifications',
  ACCESS_AVATAR_UPLOAD = 'access-avatar-upload',
  ACCESS_PREREQUISITES = 'access-prerequisites',
  ACCESS_SEQUENTIAL_PLAYLISTS = 'access-seq-playlists',
  ACCESS_PEER_AUTHORING = 'access-peer-authoring',
  ACCESS_COURSE_SUMMARY = 'access-course-summary',
  ACCESS_ENGAGE = 'access-engage',
  ACCESS_LINK_COURSES = 'access-link-courses',
  ACCESS_SOCIAL_LEARNING = 'access-social-learning',
  ACCESS_MANAGE_TEAM = 'access-manage-team'
}

export enum ProvissioningTypePermission {
  MANAGE_USERS = 'manage-users'
}

/**
 * scopedPermissions is where the definition of permission will come from.
 * It's defined by us `developers`.
 *
 * The backend will return PermissionIds (for user) and AccessPermissions (for plan).
 *
 * Based on those values, we can check against our map/constant of entities + scopes.
 *
 * @example
 * If the backend tells us that you have EDIT_CONTENT (permissionId). When you check if user can
 * EDIT (scope) a COURSE (resource). It will be allowed.
 *
 * [PermissionResources.COURSE]: {
 *  [PermissionScopes.CREATE]: [UserPermission.EDIT_CONTENT]
 * }
 */
export const scopedPermissions = {
  [PermissionResources.BILLING]: {
    [PermissionScopes.VIEW]: [UserPermission.VIEW_BILLING]
  },
  [PermissionResources.COURSE_COLLECTION]: {
    [PermissionScopes.CREATE]: [UserPermission.CREATE_COLLECTIONS],
    [PermissionScopes.EDIT]: [UserPermission.EDIT_COLLECTIONS],
    [PermissionScopes.PUBLISH]: [UserPermission.EDIT_COLLECTIONS],
    [PermissionScopes.DELETE]: [UserPermission.EDIT_COLLECTIONS],
    [PermissionScopes.REORDER]: [UserPermission.EDIT_COLLECTIONS],
    [PermissionScopes.BRANDING]: [UserPermission.EDIT_COLLECTIONS],
    [PermissionScopes.BRIEFCASE]: [UserPermission.EDIT_COLLECTIONS],
    [PermissionScopes.COMPLETION]: [UserPermission.EDIT_COLLECTIONS],
    [PermissionScopes.ACCESS_RULES]: [UserPermission.EDIT_COLLECTIONS],
    [PermissionScopes.MORE]: [UserPermission.EDIT_COLLECTIONS],
    [PermissionScopes.ADVANCED_MENU]: [UserPermission.EDIT_COLLECTIONS],
    [PermissionScopes.CHANGE_LOG]: [UserPermission.EDIT_COLLECTIONS]
  },

  [PermissionResources.COURSE_LIBRARY]: {
    [PermissionScopes.VIEW]: [UserPermission.VIEW_COURSE_LIBRARY]
  },

  [PermissionResources.COURSE]: {
    [PermissionScopes.CREATE]: [UserPermission.EDIT_CONTENT],
    [PermissionScopes.EDIT]: [UserPermission.EDIT_CONTENT],
    [PermissionScopes.PUBLISH]: [UserPermission.EDIT_CONTENT],
    [PermissionScopes.DELETE]: [UserPermission.EDIT_CONTENT],
    [PermissionScopes.REORDER]: [UserPermission.EDIT_CONTENT],
    [PermissionScopes.BRANDING]: [UserPermission.EDIT_CONTENT],
    [PermissionScopes.BRIEFCASE]: [UserPermission.EDIT_CONTENT],
    [PermissionScopes.ACCESS_RULES]: [UserPermission.EDIT_CONTENT],
    [PermissionScopes.COMPLETION]: [UserPermission.EDIT_CONTENT],
    [PermissionScopes.BRAIN_BOOST]: [UserPermission.EDIT_BRAIN_BOOST],
    [PermissionScopes.MORE]: [UserPermission.EDIT_CONTENT],
    [PermissionScopes.ADVANCED_MENU]: [UserPermission.EDIT_CONTENT],
    [PermissionScopes.CHANGE_LOG]: [UserPermission.EDIT_CONTENT],
    [PermissionScopes.FILTER_BY_USER_GROUP]: [UserPermission.FILTER_COURSES_BY_USER_GROUP],
    [PermissionScopes.FILTER_BY_ACCESS]: [UserPermission.FILTER_COURSES_BY_ACCESS]
  },

  [PermissionResources.LESSON]: {
    [PermissionScopes.CREATE]: [UserPermission.EDIT_CONTENT],
    [PermissionScopes.EDIT]: [UserPermission.ACCESS_AUTHORING],
    [PermissionScopes.PUBLISH]: [UserPermission.EDIT_CONTENT],
    [PermissionScopes.DELETE]: [UserPermission.EDIT_CONTENT],
    [PermissionScopes.REORDER]: [UserPermission.EDIT_CONTENT],
    [PermissionScopes.BRANDING]: [UserPermission.EDIT_CONTENT],
    [PermissionScopes.BRIEFCASE]: [UserPermission.EDIT_CONTENT],
    [PermissionScopes.DISPLAY]: [UserPermission.EDIT_CONTENT],
    [PermissionScopes.COMPLETION]: [UserPermission.EDIT_CONTENT],
    [PermissionScopes.ACCESS_RULES]: [UserPermission.EDIT_CONTENT],
    [PermissionScopes.CALENDAR]: [UserPermission.EDIT_CONTENT],
    [PermissionScopes.UTILITIES]: [UserPermission.EDIT_CONTENT],
    [PermissionScopes.MORE]: [UserPermission.EDIT_CONTENT],
    [PermissionScopes.ADVANCED_MENU]: [UserPermission.EDIT_CONTENT],
    [PermissionScopes.CHANGE_LOG]: [UserPermission.EDIT_CONTENT],
    [PermissionScopes.REVIEW_LESSON_TEXT]: [UserPermission.EDIT_CONTENT]
  },

  [PermissionResources.DISCUSSION]: {
    [PermissionScopes.CREATE]: [UserPermission.EDIT_CONTENT],
    [PermissionScopes.EDIT]: [UserPermission.EDIT_CONTENT],
    [PermissionScopes.PUBLISH]: [UserPermission.EDIT_CONTENT],
    [PermissionScopes.DELETE]: [UserPermission.EDIT_CONTENT],
    [PermissionScopes.POST_REPLY]: [UserPermission.POST_REPLY],
    [PermissionScopes.EDIT_ANY_COMMENT]: [UserPermission.EDIT_ANY_COMMENT],
    [PermissionScopes.FACILITATE]: [UserPermission.FACILITATE_DISCUSSIONS]
  },

  [PermissionResources.ASSESSMENT]: {
    [PermissionScopes.CREATE]: [UserPermission.EDIT_CONTENT],
    [PermissionScopes.EDIT]: [UserPermission.EDIT_CONTENT],
    [PermissionScopes.PUBLISH]: [UserPermission.EDIT_CONTENT],
    [PermissionScopes.DELETE]: [UserPermission.EDIT_CONTENT],
    [PermissionScopes.POST_REPLY]: [UserPermission.POST_REPLY],
    [PermissionScopes.EDIT_ANY_COMMENT]: [UserPermission.EDIT_ANY_COMMENT],
    [PermissionScopes.FACILITATE]: [UserPermission.FACILITATE_ASSIGNMENTS]
  },

  [PermissionResources.USER]: {
    [PermissionScopes.EDIT]: [UserPermission.EDIT_USERS]
  },

  [PermissionResources.APPLICATION_SETTINGS]: {
    [PermissionScopes.EDIT]: [UserPermission.EDIT_SETTINGS]
  },

  [PermissionResources.PLAYLIST]: {
    [PermissionScopes.CREATE]: [UserPermission.EDIT_PATHS],
    [PermissionScopes.EDIT]: [UserPermission.EDIT_PATHS],
    [PermissionScopes.PUBLISH]: [UserPermission.EDIT_PATHS],
    [PermissionScopes.DELETE]: [UserPermission.EDIT_PATHS],
    [PermissionScopes.REORDER]: [UserPermission.EDIT_PATHS],
    [PermissionScopes.BRANDING]: [UserPermission.EDIT_PATHS]
  },

  [PermissionResources.RAPID_REFRESH]: {
    [PermissionScopes.VIEW]: [UserPermission.VIEW_RAPID_REFRESH],
    [PermissionScopes.CREATE]: [UserPermission.EDIT_RAPID_REFRESH],
    [PermissionScopes.EDIT]: [UserPermission.EDIT_RAPID_REFRESH],
    [PermissionScopes.PUBLISH]: [UserPermission.EDIT_RAPID_REFRESH],
    [PermissionScopes.DELETE]: [UserPermission.EDIT_RAPID_REFRESH],
    [PermissionScopes.REORDER]: [UserPermission.EDIT_RAPID_REFRESH],
    [PermissionScopes.BRANDING]: [UserPermission.EDIT_RAPID_REFRESH],
    [PermissionScopes.ACCESS_ANALYTICS]: [
      UserPermission.VIEW_USER_ANALYTICS,
      BillingPermission.ACCESS_ANALYTICS_COURSE_COMPLETION_BY_USER
    ]
  },

  [PermissionResources.BANNER]: {
    [PermissionScopes.CREATE]: [UserPermission.EDIT_CONTENT],
    [PermissionScopes.EDIT]: [UserPermission.EDIT_CONTENT],
    [PermissionScopes.PUBLISH]: [UserPermission.EDIT_CONTENT],
    [PermissionScopes.DELETE]: [UserPermission.EDIT_CONTENT],
    [PermissionScopes.REORDER]: [UserPermission.EDIT_CONTENT],
    [PermissionScopes.BRANDING]: [UserPermission.EDIT_CONTENT]
  },

  [PermissionResources.VIRTUAL_CLASSROOM]: {
    [PermissionScopes.CREATE]: [UserPermission.EDIT_CONTENT],
    [PermissionScopes.EDIT]: [UserPermission.EDIT_CONTENT],
    [PermissionScopes.PUBLISH]: [UserPermission.EDIT_CONTENT],
    [PermissionScopes.DELETE]: [UserPermission.EDIT_CONTENT]
  },

  [PermissionResources.OBSERVATION]: {
    [PermissionScopes.CREATE]: [UserPermission.EDIT_CONTENT],
    [PermissionScopes.EDIT]: [UserPermission.EDIT_CONTENT],
    [PermissionScopes.PUBLISH]: [UserPermission.EDIT_CONTENT],
    [PermissionScopes.DELETE]: [UserPermission.EDIT_CONTENT]
  }
};

export const provisioningTypeResourceAccessPermissions = {
  [PermissionResources.USER]: [ProvissioningTypePermission.MANAGE_USERS]
};
