import { intersection, isEmpty } from 'lodash-es';

import { Roles, useUserInfo } from '@edapp/user-info';

import { provisioningTypeResourceAccessPermissions } from './constants';
import type { BillingPermission, CourseUserPermissions } from './constants';
import { checkMultipleScopePermissions, checkPermission } from './permissions';
import type { MultipleScopePermissionType, PermissionResourcesType, PermissionType } from './types';

export const useCheckPermission = (permission: PermissionType) => {
  const { userInfo, billing } = useUserInfo();
  const cleanUserPermission = userInfo?.userPermissions.map(p => ({ permissionId: p })) || [];
  const isAdmin = userInfo?.userRoles.includes(Roles.EdAdmin) || false;
  const provisioningTypePermissions = userInfo?.provisioningTypePermissions || [];

  return checkPermission(
    permission,
    billing?.permissions || [],
    cleanUserPermission,
    provisioningTypePermissions,
    isAdmin
  );
};

export const useCheckCoursePermission = (
  userPermissions: CourseUserPermissions[],
  permission: CourseUserPermissions
) => {
  return userPermissions?.includes(permission) ?? false;
};

export const useCheckMultiplePermission = (permission: MultipleScopePermissionType) => {
  const { userInfo, billing } = useUserInfo();
  const cleanUserPermission = userInfo?.userPermissions.map(p => ({ permissionId: p })) || [];
  const isAdmin = userInfo?.userRoles.includes(Roles.EdAdmin) || false;
  const provisioningTypePermissions = userInfo?.provisioningTypePermissions || [];

  return checkMultipleScopePermissions(
    permission,
    billing?.permissions || [],
    cleanUserPermission,
    provisioningTypePermissions,
    isAdmin
  );
};

export const useCheckProvisionalTypePermission = (resource: PermissionResourcesType) => {
  const { userInfo } = useUserInfo();
  const provisioningTypePermissions = userInfo?.provisioningTypePermissions || [];
  const provisioningTypeAccess = provisioningTypeResourceAccessPermissions[resource];

  const isResourceAllowed =
    !!provisioningTypeAccess &&
    !isEmpty(intersection(provisioningTypeAccess, provisioningTypePermissions));
  return isResourceAllowed;
};

export const useCheckBillingPermission = (accessPermission: BillingPermission) => {
  const { userInfo, billing } = useUserInfo();
  const isAdmin =
    userInfo?.userRoles.includes(Roles.EdAdmin) ||
    userInfo?.userRoles.includes(Roles.SafetyCultureAdmin) ||
    false;

  return isAdmin || billing?.permissions.includes(accessPermission);
};
