export const routes = {
  allInOneEditor: {
    name: 'All In One Editor',
    path: '/course/:courseId/edit',
    exact: false,
    redirects: ['/v2/course/:courseId', '/course/:courseId'],
    getRoute: ({ courseId }: { courseId: string }) => `/course/${courseId}/edit`
  },
  courseReview: {
    name: 'Course',
    path: '/course/:courseId/review',
    exact: false,
    getRoute: ({ courseId }: { courseId: string }) => `/course/${courseId}/review`
  },
  lesson: {
    name: 'Lesson',
    path: '/lesson/:lessonId',
    exact: false,
    getRoute: ({ lessonId }: { lessonId: string }) => `/lesson/${lessonId}`
  },
  facilitateDiscussionPosts: {
    name: 'Discussion',
    path: '/discussions/:discussionId/posts',
    exact: true,
    getRoute: ({ discussionId }: { discussionId: string }) => `/discussions/${discussionId}/posts`
  },
  facilitateAssignmentSubmissions: {
    name: 'Assignment Submissions',
    path: '/assignments/:assignmentId/submissions',
    exact: true,
    getRoute: ({ assignmentId }: { assignmentId: string }) =>
      `/assignments/${assignmentId}/submissions`
  },
  assessment: {
    name: 'Assessment',
    path: '/assignment/:lessonId',
    exact: false,
    getRoute: ({ lessonId }: { lessonId: string }) => `/assignment/${lessonId}`
  },
  discussion: {
    name: 'Discussion',
    path: '/discussion/:lessonId',
    exact: false,
    getRoute: ({ lessonId }: { lessonId: string }) => `/discussion/${lessonId}`
  },
  meeting: {
    name: 'Virtual Classroom',
    path: '/virtual-classroom/:lessonId',
    exact: false,
    getRoute: ({ lessonId }: { lessonId: string }) => `/virtual-classroom/${lessonId}`
  },
  observation: {
    name: 'Observation',
    path: '/practical-assessment/:lessonId',
    exact: false,
    getRoute: ({ lessonId }: { lessonId: string }) => `/practical-assessment/${lessonId}`
  },
  // TODO: https://safetyculture.atlassian.net/browse/TRAINING-549
  path: {
    name: 'Path',
    path: '/paths/:playlistId',
    getRoute: ({ playlistId }: { playlistId: string }) => `/paths/${playlistId}`
  }
};
