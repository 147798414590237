import * as React from 'react';

import { useTranslation } from 'react-i18next';

import { Box, Button, Typography } from '@edapp/ed-components';
import { globalZIndex } from '@maggie/theme/globalZIndex';

import { ChinaPolicyContent } from './ChinaPolicyContent';
import { POPUP_POLICY_TEXT } from './constant';
import { PrivacyDialog, StyledPreWrapTypography } from './styled';
import type { PolicyContentType, PopupPolicyType } from './type';

type Props = {
  isOpen: boolean;
  /**
   * The text to render - check `./constants.ts`
   */
  type: PopupPolicyType;
  onAgree: () => void;
  onDisagree: () => void;
};

export const ChinaStartupPolicyDialog: React.FC<Props> = ({
  isOpen,
  type,
  onAgree,
  onDisagree
}) => {
  const { t } = useTranslation('learners-experience');
  // internal state of dialog open as a work around for dialog on top of dialog
  const [open, setOpen] = React.useState(isOpen);
  const [policyContent, setPolicyContent] = React.useState<PolicyContentType | null>(null);

  const handleClickUserAgreement = () => {
    setPolicyContent('user-agreement');
  };

  const handeClickPrivacyPolicy = () => {
    setPolicyContent('privacy-policy');
  };

  const handleClosePolicyContent = React.useCallback(() => {
    setPolicyContent(null);
  }, []);

  React.useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  /**
   * Workaround to prevent scroll lock on the modal caused by useBodyScrollLock
   * Side Effect when:
   * - there is a policy content to be displayed, closed the policy dialog
   * - otherwise, if there is no policy content and external state isOpen, then open the policy dialog
   */
  React.useEffect(() => {
    if (policyContent) {
      setOpen(false);
      return;
    }

    if (!policyContent && isOpen) {
      setOpen(true);
    }
  }, [policyContent]);

  return (
    <>
      <PrivacyDialog
        isOpen={open}
        dialogWrapperStyle={{ zIndex: globalZIndex.PRIVACY_DIALOG }}
        testId={`dialog-china-policy-${type}`}
      >
        <Box px={4} py={5}>
          {/* Header */}
          <Typography as="p" textAlign="center" weight="700">
            EdApp
          </Typography>
          {/* User Agreement & Privacy Policy Notification */}
          <Typography as="p" textAlign="center" weight="700">
            用户协议及隐私政策申明
          </Typography>

          {/* Body */}
          <StyledPreWrapTypography as="p" my={4}>
            {POPUP_POLICY_TEXT[type]}
          </StyledPreWrapTypography>

          {/* Footer */}
          {/* You can read the complete User Agreement and Privacy Policy */}
          <Typography as="p" mb={3}>
            您可以阅读完整版
            <a role="button" onClick={handleClickUserAgreement}>
              用户协议
            </a>
            和
            <a role="button" onClick={handeClickPrivacyPolicy}>
              隐私政策
            </a>
            。
          </Typography>

          <Box mb={2} flexShrink={0}>
            <Button variant="primary" isFullWidth={true} onClick={onAgree}>
              {t('common.agree')}
            </Button>
          </Box>

          {onDisagree && (
            <Button variant="pale" isFullWidth={true} onClick={onDisagree}>
              {t('common.disagree')}
            </Button>
          )}
        </Box>
      </PrivacyDialog>
      <ChinaPolicyContent
        isOpen={!!policyContent}
        type={policyContent!}
        onClose={handleClosePolicyContent}
      />
    </>
  );
};
