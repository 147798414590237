import * as React from 'react';

export function getBrowserVisibilityChangeEventName() {
  if (typeof (document as any).msHidden !== 'undefined') {
    return 'msvisibilitychange';
  } else if (typeof (document as any).webkitHidden !== 'undefined') {
    return 'webkitvisibilitychange';
  }

  return 'visibilitychange';
}

function getBrowserDocumentHiddenProp() {
  if (typeof document.hidden !== 'undefined') {
    return 'hidden';
  } else if (typeof (document as any).msHidden !== 'undefined') {
    return 'msHidden';
  } else if (typeof (document as any).webkitHidden !== 'undefined') {
    return 'webkitHidden';
  }
}

export function getIsVisible() {
  const documentHiddenProp = getBrowserDocumentHiddenProp();

  if (!documentHiddenProp) {
    return true;
  }

  return !document[documentHiddenProp];
}

type Callback = (isVisible: boolean) => void;

export function usePageVisibility(callback?: Callback) {
  const [isVisible, setIsVisible] = React.useState(getIsVisible());

  const handleVisibilityChange = React.useCallback(() => {
    const newIsVisible = getIsVisible();

    callback?.(newIsVisible);

    setIsVisible(newIsVisible);
  }, [callback]);

  React.useEffect(() => {
    const visibilityChangeEvent = getBrowserVisibilityChangeEventName();

    document.addEventListener(visibilityChangeEvent, handleVisibilityChange);

    return () => {
      document.removeEventListener(visibilityChangeEvent, handleVisibilityChange);
    };
  }, [handleVisibilityChange]);

  return React.useMemo(
    () => ({
      isVisible,
      getIsVisible
    }),
    [isVisible]
  );
}
