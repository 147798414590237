import type {
  ApplicationContextResponse,
  ApplicationResponse,
  BillingPlanDetailsType
} from '@edapp/hippo-client';

import type { UserResponse } from './types';

export enum QueryKeys {
  GET_USER = 'get-user'
}

export const Roles = {
  AnonymousUser: 'anonymous-user',
  EdAdmin: 'ed-admin',
  SafetyCultureAdmin: 'safety-culture-admin',
  Owner: 'account-owner',
  Admin: 'account-admin',
  SeoAdmin: 'seo-admin',
  ContentAuthor: 'content-author',
  RestrictedAuthor: 'restricted-author',
  ManagerAnalytics: 'manager-analytics',
  Learner: 'app-user',
  PrizingUser: 'prizing-user',
  ExcludedFromAnalytics: 'exclude-from-analytics',
  HiddenUser: 'hidden-user',
  Reviewer: 'reviewer',
  DemoUser: 'demo-user',
  Facilitator: 'facilitator',
  IndividualLearner: 'individual-learner',
  CourseReviewer: 'course-reviewer',
  ManageTeams: 'manage-teams' // This role is only used in SC Training
} as const;

export const initialUserData: UserResponse = {
  userId: '',
  userEmail: '',
  userFirstName: '',
  userLastName: '',
  userName: '',
  userIsAdministrator: false,
  userApplicationId: '',
  userRoles: [],
  userPermissions: [],
  hasVisitedBrainBoost: false,
  hasVisitedCustomAchievement: false,
  userAvatarUrl: '',
  userCustomFields: [],
  lessonAutoSavingEnabled: false,
  provisioningTypePermissions: []
};
export const initialApplicationData: ApplicationResponse & ApplicationContextResponse = {
  name: '',
  isSponsor: false,
  isUnifiedExperience: false,
  isSpacedRepetitionAllowed: false,
  isCompletionCertificateAllowed: false,
  totalPublishedCourses: 0,
  totalLearners: 0,
  totalUsers: 0,
  dynamicUserGroupEnabled: false,
  leaderboardEnabled: false,
  offlineEnabled: false,
  brainboostEnabled: false,
  hideActivityFeedEnabled: true,
  mindfulLearningEnabled: false,
  courseCompletionCertificateEnabled: true,
  avatarUploadEnabled: false,
  coursePrerequisitesEnabled: false,
  starsEnabled: false,
  starBarEnabled: false,
  certificationEnabled: false,
  coursePublicPreviewLinksEnabled: true
};
export const initialBillingData: BillingPlanDetailsType = {
  permissions: [],
  currentPlan: {
    id: '',
    type: 'AuthoringAndDelivery',
    name: '',
    minimumBillingUsers: 0,
    price: 0,
    isStripeBillable: true,
    features: [],
    limits: [],
    rank: 0,
    isVisible: true,
    displayName: '',
    startDate: '',
    endDate: '',
    isOnFreePlan: false,
    isEnterprise: true,
    isFreemium: false,
    isTrial: false
  },
  hasParticipatedInTrial: false,
  planAccess: {
    hasAccess: true,
    hasAccessReason: null,
    lockedDate: null,
    lessonLimit: null
  },
  planIds: []
};
