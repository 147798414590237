import { useEffect } from 'react';

import type { InfiniteData, MutationFunction, QueryKey } from '@tanstack/react-query';
import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';

import { useAPIAccess } from './providers';
import type {
  HippoError,
  HippoMutationFunction,
  HippoQueryFunction,
  PaginatedResponse,
  UseHippoMutationOptions,
  UseHippoMutationResult,
  UseHippoPaginationOptions,
  UseHippoPaginationResult,
  UseHippoQueryOptions,
  UseHippoQueryResult
} from './types';
import { retry } from './utils';

function useHippoMutationFn<TResponse, TRequest>(
  mutationFn: HippoMutationFunction<TResponse, TRequest>
): MutationFunction<TResponse, TRequest> {
  const { apiUrl, token } = useAPIAccess();
  return mutationFn(apiUrl, token);
}

export function useHippoQuery<TResponse, TData = TResponse>(
  queryKey: QueryKey,
  queryFn: HippoQueryFunction<TResponse>,
  options?: UseHippoQueryOptions<TResponse, TData>
): UseHippoQueryResult<TData> {
  const { apiUrl, token } = useAPIAccess();

  const query = useQuery<TResponse, HippoError, TData>({
    queryKey,
    queryFn: queryFn(apiUrl, token),
    retry: options?.retry ? options.retry : retry(3),
    ...options
  });

  // onSettled
  useEffect(() => {
    if (!query.isFetched) {
      return; // nothing to do here
    }

    options?.onSettled?.(query.data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.isFetched, query.data]);

  // onSuccess
  useEffect(() => {
    if (!query.isSuccess) {
      return; // nothing to do here
    }

    options?.onSuccess?.(query.data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.isSuccess, query.data]);

  // onError
  useEffect(() => {
    if (!query.isError) {
      return; // nothing to do here
    }

    options?.onError?.(query.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.isError, query.data]);

  return query;
}

export function useHippoPagination<TResponse extends PaginatedResponse<any>>(
  queryKey: QueryKey,
  queryFn: HippoQueryFunction<TResponse>,
  options?: UseHippoPaginationOptions<TResponse>
): UseHippoPaginationResult<TResponse> {
  const { apiUrl, token } = useAPIAccess();

  const query = useInfiniteQuery<TResponse, HippoError, InfiniteData<TResponse>, QueryKey, number>({
    queryKey,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    queryFn: queryFn(apiUrl, token),
    ...options,
    getNextPageParam: (_, allPages) => {
      const currentPage = allPages.length; // the length of all pages indiciate which page user is up to
      return currentPage + 1; // next page
    },
    initialPageParam: 1
  });

  // onSettled
  useEffect(() => {
    if (!query.isFetched) {
      return; // nothing to do here
    }

    options?.onSettled?.(query.data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.isFetched, query.data]);

  // onSuccess
  useEffect(() => {
    if (!query.isSuccess) {
      return; // nothing to do here
    }

    options?.onSuccess?.(query.data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.isSuccess, query.data]);

  // onError
  useEffect(() => {
    if (!query.isError) {
      return; // nothing to do here
    }

    options?.onError?.(query.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.isError, query.data]);

  const lastPage = !!query.data?.pages.length
    ? query.data.pages[query.data?.pages.length - 1]
    : undefined;

  const totalItems = query.data?.pages.reduce((count, { items }) => count + items.length, 0) || 0;
  const totalCount = lastPage?.totalCount || 0;
  const hasNextPage = !query.error && !query.isFetching && totalCount > totalItems;

  return {
    ...query,
    hasNextPage,
    totalCount: lastPage?.totalCount || 0
  };
}

export function useHippoMutation<TResponse, TRequest, TError = HippoError>(
  mutationFn: HippoMutationFunction<TResponse, TRequest>,
  options?: UseHippoMutationOptions<TResponse, TRequest, TError>
): UseHippoMutationResult<TResponse, TRequest, TError> {
  const mutation = useMutation<TResponse, TError, TRequest>({
    mutationFn: useHippoMutationFn(mutationFn),
    ...options
  });

  return {
    ...mutation,
    isLoading: mutation.status === 'pending'
  };
}

/**
 * Flatten the @see InfiniteData pagination responses into one paginated response
 * @param Infinite data
 * @returns Combined Paginated response
 */
export function flattenPages<TResponse>(
  data?: InfiniteData<PaginatedResponse<TResponse>>,
  initial: PaginatedResponse<TResponse> = {
    totalCount: 0,
    items: []
  }
): PaginatedResponse<TResponse> {
  return (
    data?.pages.reduce<PaginatedResponse<TResponse>>(
      ({ items }, cur) => ({
        totalCount: cur.totalCount,
        items: [...items, ...cur.items]
      }),
      initial
    ) ?? initial
  );
}
