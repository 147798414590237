import * as React from 'react';

import styled, { css } from 'styled-components';

import {
  Box,
  CalendarIcon,
  ChevronRightIcon,
  StatusLabel,
  Typography,
  UserIcon
} from '@edapp/ed-components';

import { ObservationStatus } from '../constants';
import { getDateFormat, getStatusColor } from '../utils';

type SubmissionCardItemProps = {
  id: string;
  createdByName: string;
  completedByName?: string;
  status: ObservationStatus;
  dateCreated: string;
  dateCompleted?: string;
  onClick?: () => void;
};

export const SubmissionCardItem: React.FC<SubmissionCardItemProps> = ({
  id,
  createdByName,
  completedByName,
  dateCreated,
  dateCompleted,
  status,
  onClick,
  ...rest
}) => {
  const statusObject = getStatusColor(status);
  const dateDisplay = getDateFormat(
    dateCompleted && status !== ObservationStatus.InProgress ? dateCompleted : dateCreated
  );

  const assessorName =
    !!completedByName && createdByName !== completedByName
      ? createdByName + ', ' + completedByName
      : createdByName;

  return (
    <ItemBox
      flex
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      onClick={onClick}
      testId={`submission-card-item-${id}`}
      {...rest}
    >
      <Box flex flexDirection="column" pr={1}>
        <Box flex alignItems="center">
          <CalendarIcon color="text" />
          <Typography color="text" pl={1} lineClamp={1} wordBreak="break-all">
            {dateDisplay}
          </Typography>
        </Box>
        <Box flex alignItems="center">
          <UserIcon color="textMuted" />
          <Typography color="textMuted" pl={1} lineClamp={1} wordBreak="break-all">
            {assessorName}
          </Typography>
        </Box>
      </Box>
      <Box>
        <StatusLabel
          background={statusObject.color}
          text={statusObject.color}
          label={statusObject.text}
        />
        {!!onClick && <StyledChevronRightIcon size="sm" color="textMuted" />}
      </Box>
    </ItemBox>
  );
};

const StyledChevronRightIcon = styled(ChevronRightIcon)(
  ({ theme }) => css`
    margin-left: ${theme.space(3)}px;
    @media (max-width: ${theme.breakpoints.sm}rem) {
      margin-left: ${theme.space(1)}px;
    }
  `
);

const ItemBox = styled(Box)`
  padding: ${({ theme }) => theme.space(2)}px;
`;
