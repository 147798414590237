import type { AuthoringTypes } from '@edapp/authoring-logic';
import type { RequestTypes } from '@edapp/request';

export type CourseCollectionBrainBoostInfo = {
  enabledSlides: number; //Boosted slides
  totalSlides: number; // Interactable slides
};

export type FirstCourseLesson = { id: string; title: string; lessonType?: string };

export type CourseCollectionItem = {
  id: string;
  created: string;
  modified: string;
  brandingTextColor: 'white' | 'black';
  brandingImage: string;
  title: string;
  description: string;
  thumbnail: string;
  externalIdentifier: string;
  numberOfCourses: number;
  lessonsInFirstCourse: Array<FirstCourseLesson> | null;
  isManual: boolean;
  isPublished: boolean;
  courses: AuthoringTypes.CourseSummaryTypeCourseId[];
  brainBoost: CourseCollectionBrainBoostInfo;
};

export enum CourseListStatusEnum {
  ALL = 'all',
  ARCHIVED = 'archived',
  DRAFTED = 'drafted',
  PUBLISHED = 'published',
  SCHEDULED = 'scheduled'
}

export enum CourseAccessEnum {
  ALL = 'all',
  OWNED_OR_SHARED = 'owned-or-shared'
}

export enum FilterDateTypeEnum {
  CREATED = 'created',
  MODIFIED = 'modified',
  PUBLISHED = 'published',
  ANY = 'any'
}

export type FilterDate = {
  type: FilterDateTypeEnum;
  from: Date | undefined;
  to: Date | undefined;
};

export type CourseListFilter = Partial<{
  access: CourseAccessEnum;
  searchTerm: string;
  status: CourseListStatusEnum | undefined;
  usergroupIds: string[];
  locales: string[];
  universalAccess: boolean;
  isGroupTrainingEnabled?: boolean;
  date: FilterDate | undefined;
}>;

export enum SortByEnum {
  CREATED = 'created',
  MODIFIED = 'modified',
  PUBLISHED = 'published',
  ALPHABETICALLY = 'alphabetically',
  COLLECTIONS = 'collections'
}

export type FilterSort = {
  by: SortByEnum;
  direction: 'asc' | 'desc';
};

export type GetFilteredCourseCollectionsRequest = {
  page: number;
  filter: CourseListFilter | undefined;
  sort: FilterSort | undefined;
};

export type GetFilteredCourseCollectionsResponse = GetCourseCollectionsResponse;

export type GetCourseCollectionsRequest = {
  page: number;
  includeDraftCourseInCount: boolean;
  includeCourseSummary: boolean;
  includeNotManual: boolean;
  orderByIsManual: boolean;
};

/**
 * We're abstracting the logic of course collection filters inside of the `api.ts`
 *
 * It will call different endpoints based on what parameters are passed into the hook.
 */
export type JoinedCourseCollectionsRequest = {
  page: number;
  includes?: Omit<GetCourseCollectionsRequest, 'page'>;
  filter?: CourseListFilter;
  sort?: FilterSort;
};

export type GetCourseCollectionsResponse = RequestTypes.PaginatedResponse<CourseCollectionItem>;

export type UserGroupResponse = { id: string; name: string };
export type LocaleResponse = { code: string; name: string };
export type CourseStatusResponse = { code: CourseListStatusEnum; total: number };

export type FilterInitResponse = {
  statuses: CourseStatusResponse[];
  usergroups: UserGroupResponse[];
  locales: LocaleResponse[];
};

export type ChangeRankRequest = { ids: string[] };

export type DeleteCourseCollectionParams = { id: string; cascade: boolean };

export type DeleteCourseCollectionRequest = { cascade: boolean };
