import * as React from 'react';
import type { UserInfoContent } from './types';

export const UserInfoContext = React.createContext<UserInfoContent | undefined>(undefined);

export const useUserInfo = () => {
  const context = React.useContext(UserInfoContext);

  if (!context) {
    throw new Error(
      'Usage of "useUserInfo" should be within the UserInfoContext.Provider component'
    );
  }

  return context;
};
