import { intersection, isEmpty } from 'lodash-es';

import type { ProvissioningTypePermission, UserPermission } from './constants';
import {
  BillingPermission,
  provisioningTypeResourceAccessPermissions,
  scopedPermissions
} from './constants';
import type { MultipleScopePermissionType, PermissionType, UserPermissionType } from './types';

/**
 * It filters out the permissionIds that don't include the requested resourceId
 *
 * @param resourceId - ids of resources the user has access to
 * @param userPermission - permission ids and its resources
 *
 * @example
 * You can EDIT_CONTENT for resourceId `xxx` only.
 */
const calculateUserPermissions = (
  resourceId: string | undefined,
  userPermissions: UserPermissionType[]
): string[] => {
  return userPermissions.reduce((acc, userPermission) => {
    if (!userPermission.courseIds || isEmpty(userPermission.courseIds)) {
      acc.push(userPermission.permissionId);
      return acc;
    }

    if (!resourceId || resourceId === 'new') {
      acc.push(userPermission.permissionId);
      return acc;
    }

    if (userPermission.courseIds.includes(resourceId)) {
      acc.push(userPermission.permissionId);
      return acc;
    }

    return acc;
  }, [] as string[]);
};

/**
 *
 * This is a function with a lot of logic.
 * Please, prioritise simplicity over complexity.
 * Let's keep it simple and easy to understand.
 *
 * @param item - Permission that will be checked against
 * @param planPermissions - Features that require plan access (from billing)
 * @param userPermission - Permission Ids that user has access to - they will be checked against `scopedPermissions`
 * @param isEdAdmin - Tells if the user is a super admin (edapp)
 */
export const checkPermission = (
  item: PermissionType,
  planPermissions: BillingPermission[],
  userPermissions: UserPermissionType[],
  provisioningTypePermissions: ProvissioningTypePermission[],
  isEdAdmin: boolean
): boolean => {
  // 0. Super admins always have access to all features
  if (!!isEdAdmin) {
    return true;
  }

  const resourceScopedPermissions = scopedPermissions[item.resource];

  // 1. Check against scopedPermissions - if it's a valid scope for that resource
  if (!resourceScopedPermissions[item.scope]) {
    throw Error(`Resource ${item.resource} doesnt have scope ${item.scope}`);
  }

  // 2. Checks against permission scope
  const permissions = calculateUserPermissions(item.resourceId, userPermissions);
  const isResourceAllowed = !isEmpty(
    intersection(resourceScopedPermissions[item.scope], permissions)
  );
  if (!isResourceAllowed) {
    return false;
  }

  // 3. Check against provisioning type permissions
  const provisioningTypeAccess = provisioningTypeResourceAccessPermissions[item.resource];
  if (
    !!provisioningTypeAccess &&
    isEmpty(intersection(provisioningTypeAccess, provisioningTypePermissions))
  ) {
    return false;
  }

  // 4. If plan access is required, check if your plan includes that feature
  if (
    !!item.accessPermission &&
    !planPermissions.includes(BillingPermission[item.accessPermission])
  ) {
    return false;
  }

  return true;
};

export const checkMultipleScopePermissions = (
  item: MultipleScopePermissionType,
  planPermissions: BillingPermission[],
  userPermissions: UserPermissionType[],
  provisioningTypePermissions: ProvissioningTypePermission[],
  isEdAdmin: boolean
) => {
  const permitted = item.scope.reduce((acc, scope) => {
    const permission: PermissionType = {
      scope,
      resource: item.resource,
      accessPermission: item.accessPermission,
      resourceId: item.resourceId
    };

    acc[scope] = checkPermission(
      permission,
      planPermissions,
      userPermissions,
      provisioningTypePermissions,
      isEdAdmin
    );

    return acc;
  }, {});

  return permitted;
};

export const hasBillingPermissions = (
  requiredPermissions: BillingPermission[],
  planPermissions: BillingPermission[],
  isEdAdmin: boolean
) => {
  if (!!isEdAdmin) {
    return true;
  }
  const hasAllPermissions = requiredPermissions.every(permission =>
    planPermissions.includes(permission)
  );

  return hasAllPermissions;
};

export const hasUserPermissions = (
  requiredPermissions: UserPermission[],
  userPermissions: UserPermission[],
  isEdAdmin: boolean
) => {
  if (!!isEdAdmin) {
    return true;
  }

  const hasAllPermissions = requiredPermissions.every(permission =>
    userPermissions.includes(permission)
  );
  return hasAllPermissions;
};

export const hasPermissions = (
  requiredPermissions: string[],
  userPermissions: string[],
  billingPermissions: BillingPermission[]
) => {
  const permissions = userPermissions.concat(billingPermissions as string[]);

  const hasAllPermissions = requiredPermissions.every(permission =>
    permissions.includes(permission)
  );
  return hasAllPermissions;
};

export const hasAnyPermissions = (
  permissions: string[],
  planPermissions: BillingPermission[],
  userPermissions: UserPermission[]
) => {
  const hasPermission =
    planPermissions.some(permission => permissions.includes(permission)) ||
    userPermissions.some(permission => permissions.includes(permission));

  return hasPermission;
};
