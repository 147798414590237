import * as React from 'react';

import type { Context } from './FeatureFlagsContext';
import { FeatureFlagsContext } from './FeatureFlagsContext';
import type { FeatureFlags, FlagKeys } from './types';

/**
 * @description
 * Accepts an array of flag names, returning a corresponding array of flag values
 *
 * @example
 * const [flagAValue, flagBValue] = useFeatureFlags(['flag-name-a', 'flag-name-b']);
 */
export function useFeatureFlags<Flags extends FlagKeys>(flagNames: Flags[]): boolean[] {
  return useFeatureFlagsWithVariation(flagNames).map(flag => flag.enabled);
}

export function useFeatureFlagsWithVariation<Flags extends FlagKeys>(
  flagNames: Flags[]
): { enabled: boolean; variationValue?: string }[] {
  const { flags, fetchFlags } = React.useContext<Context<Flags>>(FeatureFlagsContext);
  const [featureFlagValues, expiredFlags] = flagNames.reduce<
    [Array<{ enabled: boolean; variationValue?: string }>, Flags[]]
  >(
    (acc, flag) => {
      const featureFlag = flags[flag];

      if (!featureFlag) {
        return [
          [...acc[0], { enabled: false }],
          [...acc[1], flag]
        ];
      }

      return [
        [
          ...acc[0],
          { enabled: featureFlag.enabled, variationValue: featureFlag.variationValue || undefined }
        ],
        featureFlag.expiry <= Date.now() ? [...acc[1], flag] : acc[1]
      ];
    },
    [[], []]
  );

  React.useEffect(() => {
    if (!!expiredFlags.length) {
      fetchFlags(expiredFlags);
    }
  }, [expiredFlags.length]);

  return featureFlagValues;
}

/**
 * @description
 * Accepts a callback function that receives the context
 *
 * @example
 * const isFeatureFlagged = useFeatureFlagsContext((flags) => {
 *   return !!(flags['flag-name-a'] && flags['flag-name-b']);
 * });
 *
 */
export function useFeatureFlagsContext<Flags extends FlagKeys, TValue>(
  callback: (context: FeatureFlags<Flags>) => TValue
): TValue {
  const flagsFromContext = React.useContext<Context<Flags>>(FeatureFlagsContext).flags;
  return callback(flagsFromContext);
}

export function useFetchFeatureFlags<Flags extends FlagKeys>(): (
  flagNames?: Flags[]
) => Promise<void> {
  return React.useContext<Context<Flags>>(FeatureFlagsContext).fetchFlags;
}

export function useFetchFeatureFlag<Flags extends FlagKeys>(): (flagName: Flags) => Promise<any> {
  return React.useContext<Context<Flags>>(FeatureFlagsContext).fetchFlag;
}
