import type { Store } from 'redux';

import type { LxStoreState } from '@maggie/store/types';

import { backboneResumeActiveEventHandler } from './events/backboneResumeActiveEventHandler';
import { backboneResumeEventHandler } from './events/backboneResumeEventHandler';
import { backboneStarEventHandler } from './events/backboneStarEventHandler';

export type BackboneEventHandler = (store: Store<LxStoreState>) => (...args: any[]) => any;

const addBackboneListeners = (store: Store<LxStoreState>) => {
  Backbone.Events.on('star', backboneStarEventHandler(store));

  Backbone.Events.on('resume', backboneResumeEventHandler(store));
  Backbone.Events.on('resume active', backboneResumeActiveEventHandler(store));
};

export { addBackboneListeners };
