import * as React from 'react';

import type { i18n } from 'i18next';
import { ThemeProvider as Provider } from 'styled-components';

import { Loading } from '@edapp/ed-components';
import { ScEdappUIConfigAdapter, localizationWithI18next } from '@edapp/sc-web-ui';
import { getTheme, useAppearance } from '@edapp/themes';

import { Theme } from './theme';
import type { ThemeType } from './theme';

type Props = {
  i18n?: i18n;
};

export const ThemeProvider: React.FC<React.PropsWithChildren<Props>> = ({ i18n, children }) => {
  const { scheme } = useAppearance();
  const theme = getTheme<ThemeType>(scheme, Theme as ThemeType);

  return (
    <Provider theme={theme}>
      <React.Suspense fallback={<Loading />}>
        <ScEdappUIConfigAdapter
          edappTheme={Theme}
          localization={!!i18n ? localizationWithI18next(i18n.t, 'sc-web-ui') : undefined}
          isUxp={scheme === 'sc-light' || scheme === 'sc-dark'}
          schemeType={scheme}
        >
          {children}
        </ScEdappUIConfigAdapter>
      </React.Suspense>
    </Provider>
  );
};
